.calendarCheckbox {
  display: block;
}

.year {
  color: var(--primary);
  font-weight: 300;
  font-size: 3rem;
  text-align: center;
}

.container {
  max-width: 45rem;
  margin: 0 auto;
}

.calendar {
  --event-height: 20px;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  list-style: none;
  margin: 0;
  padding: 0;
}

.dayHeader {
  padding: 0.75rem 0;
  text-align: center;
  color: #888;
  font-weight: bold;
}

.dayContainer {
  position: relative;
  margin: 0.25rem;
  box-shadow: inset 0 1px 0 #ccc;
  padding-bottom: 85%;
}

.day {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
}

.today {
  background-color: #eee;
}

.dayFirstOfMonth {
  box-shadow: inset 0 2px 0 var(--primary);
}

.month,
.dayOfMonth {
  padding: 0.125rem 0;
}

.dayOfMonth,
.month,
.dayHeader {
  font-size: 0.75rem;
}

.dayOfMonth {
  color: #444;
}

.month {
  text-transform: uppercase;
  letter-spacing: 0.05em;
  color: var(--primary);
  font-weight: bold;
}

.events {
  flex: 1;
  position: relative;
  margin: 0 -4px;
}

.eventContainer {
  position: absolute;
  height: var(--event-height);
  padding: 1px 4px;
}

.event {
  font-size: 0.75rem;
  background-color: var(--secondary);
  color: #fff;
  font-weight: bold;
  border-radius: 3px;
  white-space: nowrap;
  line-height: calc(var(--event-height) - 2px);
  padding: 0 0.25rem;
}
